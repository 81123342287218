//@import "compass/css3";

@import "../utils/_mixins";
@import "../utils/_variables";
@import "../style-guide/_variables";
@import "../style-guide/_responsive";

$darkBlue: #042632;
$gold: #9f852c;
$silver: #798795;
$bronze: #83512f;

$cardSpacing: 10px;

$mostPopularColor: #4B839A;
$bestValueColor: #3CB879;

body.buy_platinum {
    @-webkit-keyframes flashflip {
        0% {
            opacity: 0;
            -webkit-transform: rotateY(-180deg);
            transform: rotatey(-180deg);
        }

        100% {
            opacity: 1;
            -webkit-transform: rotateY(+180deg);
            transform: rotatey(+180deg);
        }
    }

    .backfaceVisibility {
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
    }

    .mobile {
        display: none;
    }

    @include mq(mobile) {
        .mobile {
            display: unset;
        }

        .nonMobile {
            display: none;
        }
    }


    #colorbox.modCardOverlay {
        #cboxContent {
            background: none;
        }

        .card {
            width: 256px;
            height: 376px;
            display: inline-block;
            float: left;
            color: #fce9be;
            text-transform: uppercase;
            position: relative;
            background-size: contain;
            background-repeat: no-repeat;

            .cardtitle,
            .cardType,
            .cardRarity,
            .cardEffect,
            .cardCompat {
                position: absolute;
                text-align: center;
                width: 100%;
            }

            .cardtitle {
                top: 245px;
                font-size: 20px;
            }

            .cardEffect {
                top: 270px;
                line-height: 1;
            }

            .cardCompat {
                top: 82.7%;
                font-weight: bold;
            }
        }
    }


    .storeBar {
        display: none;
    }

    #buyplatExpanded {
        margin-top: calc(var(--page-header-height) + #{$SPACE_LARGE});
        margin-bottom: $SPACE_LARGER;

        .section {
            scroll-margin-top: var(--page-header-height);
        }

        .platPacks {
            border: 3px solid #444444;
            display: flex;

            .heroPlat {
                width: 50%;
                max-height: 100%;
                display: inline-block;
                float: left;
                //height: 420px;
                background-image: url('/images/buy-plat/expanded/hero-background.jpg');
                background-repeat: no-repeat;
                background-position-x: 50%;
                background-size: cover;
                color: #fff;
                position: relative;
                background-color: $darkBlue;
                overflow: hidden;

                @media (max-width: 1110px) {
                    display: none;
                }

                .cornerTab {
                    -ms-transform: rotate(-40deg);
                    -webkit-transform: rotate(-40deg);
                    transform: rotate(-40deg);
                    position: absolute;
                    width: 260px;
                    height: 140px;
                    text-align: center;
                    padding: 80px 40px 0px 25px;
                    left: -75px;
                    top: -40px;
                    text-transform: uppercase;
                    font-size: 25px;
                    font-weight: bold;

                    &.bestValue {
                        background: $bestValueColor;
                    }

                    &.mostPopular {
                        background: $mostPopularColor;
                        padding: 94px 27px 0px 16px;
                        font-size: 21px;
                    }
                }

                .heroImg {
                    background-image: url('../images/buy-plat/expanded/platPack.png');
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position-x: 50%;
                    background-position-y: 50%;

                    &.hoverPointer {
                        cursor: pointer;
                    }
                }

                .desc {
                    z-index: 10;
                    margin: -65px auto 0;
                    display: table;

                    .platInfo {
                        fill: white;
                        cursor: pointer;
                        position: absolute;
                        top: 20px;
                        left: 20px;
                    }

                    .plat {
                        float: left;
                        padding: 0 20px;

                        .icon {
                            background-image: url('../images/buy-plat/expanded/platIconWhite.png');
                            background-size: 100%;
                            width: 40px;
                            height: 40px;
                            display: inline-block;

                            &.sku160,
                            &.sku18,
                            &.sku17 {
                                background-image: url('../images/buy-plat/expanded/platIconNavy.png');
                            }
                        }

                        .num {
                            font-weight: bold;
                            display: inline-block;
                            font-size: 30px;
                            margin-left: 5px;

                            &.sku160,
                            &.sku18,
                            &.sku17 {
                                color: $darkBlue;
                            }

                            span.amount {
                                display: block;
                                margin-bottom: -13px;
                            }

                            span.word {
                                font-weight: normal;
                                text-transform: uppercase;
                                font-size: 16px;
                            }
                        }
                    }

                    .packName {
                        display: inline-block;
                        padding: 0 20px;
                        text-transform: uppercase;
                        border-left: 1px solid white;

                        span {
                            font-size: 32px;
                            font-weight: bold;
                            display: block;
                        }
                    }
                }

                &.sku257 {
                    background-image: url('/images/buy-plat/expanded/hero-background-gold.jpg');

                    .heroImg {
                        background-image: url('../images/buy-plat/expanded/platPackTrinity-shadow.png');
                        background-size: contain;
                    }
                }

                &.sku258 {
                    background-image: url('/images/buy-plat/expanded/hero-background-silver.jpg');

                    .heroImg {
                        background-image: url('../images/buy-plat/expanded/platPackTwain-shadow.png');
                        background-size: contain;
                    }
                }

                &.sku259 {
                    background-image: url('/images/buy-plat/expanded/hero-background-bronze.jpg');

                    .heroImg {
                        background-image: url('../images/buy-plat/expanded/platPackSundry-shadow.png');
                        background-size: contain;
                    }
                }

                &.sku160 .heroImg {
                    background-image: url('../images/buy-plat/expanded/platPack370-shadow.png');
                    background-size: contain;
                }

                &.sku18 .heroImg {
                    background-image: url('../images/buy-plat/expanded/platPack170-shadow.png');
                    background-size: contain;
                }

                &.sku17 .heroImg {
                    background-image: url('../images/buy-plat/expanded/platPack75-shadow.png');
                    background-size: contain;

                }
            }

            ul.platList {
                width: 50%;
                display: inline-block;
                float: left;

                @include mq(mobile) {
                    margin: 0;
                    padding: 0;
                }

                @media (max-width: 1110px) {
                    width: 100%;
                    float: none;
                }

                li {
                    min-height: 70px;
                    height: auto;
                    border-bottom: 1px solid #c7c7c7;
                    padding: 5px 15px;
                    background-color: #fff;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;

                    @include mq(ib) {
                        height: auto;
                    }

                    @media (max-width: 1110px) {
                        height: auto;
                        overflow: auto;
                    }

                    @include mq(steambrowser) {
                        overflow: hidden;
                    }

                    @include mq(tablet) {
                        overflow: hidden;
                    }

                    &:last-child {
                        border-bottom: none;
                    }

                    .indicator {
                        //@include transform-origin(0, 100%);
                        //@include transform(rotate(-45deg));
                        transform: rotate(45deg) translateX(-55%) translateY(40%);
                        position: absolute;
                        width: 31px;
                        height: 35px;
                        background: $darkBlue;
                        margin: 3px 0 0 30px;
                        left: -31px;
                        //top: 17px;
                        display: none;
                        align-self: center;

                        @media (max-width: 1110px) {
                            display: none !important;
                        }
                    }

                    .desc {
                        display: inline-block;
                        margin: 13px 0 10px 25px;

                        @media (max-width: 1110px) {
                            margin-left: 0;
                            width: 50%;
                        }

                        @include mq(steambrowser) {
                            margin: 9px 0 10px 25px;
                        }

                        .icon {
                            background-image: url('../images/buy-plat/expanded/platIconBlue.png');
                            background-size: 100%;
                            width: 32px;
                            height: 32px;
                            display: inline-block;
                            background-repeat: no-repeat;
                            position: relative;
                            //top: -4px;
                            flex-shrink: 0;

                            @include mq(mobile) {
                                display: none;
                            }
                        }

                        .platInfo {
                            display: grid;
                            grid-template-columns: 31px 1fr;
                            align-items: center;
                            color: $darkBlue;

                            @include mq(mobile) {
                                display: flex;
                                grid-template-columns: auto;
                            }

                            .saleIconHolder {
                                position: relative;
                                background-color: $couponRed;
                                border-radius: 20px;
                                width: 31px;
                                height: 31px;
                                top: 7px;

                                img {
                                    position: absolute;
                                    filter: invert(100%);
                                    top: 5px;
                                    left: 6px;
                                }

                                &.noSale {
                                    width: 38px;
                                    background-color: white;
                                    border: grey 2px solid;

                                    img {
                                        filter: invert(50%);
                                        top: 4px;
                                        left: 4px;
                                    }
                                }
                            }

                            .platMods {
                                font-size: 23px;
                                text-transform: uppercase;
                                position: relative;
                                margin-left: 10px;
                                top: 9px;

                                @include mq(mobile) {
                                    margin-left: 0;
                                }

                                @include mq(tablet) {
                                    font-size: 16px;
                                }

                                span.platinum {
                                    font-weight: bold;
                                }

                                .bonus {
                                    font-size: 14px;
                                    font-weight: bold;

                                    //white-space: normal;
                                    &.active {
                                        padding-left: 22px;
                                    }

                                    &.inactive {
                                        color: black;
                                        text-transform: none;
                                    }

                                    .Icon {
                                        position: absolute;
                                        left: 0;
                                    }
                                }

                                .plusMods {
                                    overflow-wrap: break-word;
                                    white-space: normal;
                                }
                            }
                        }
                    }

                    .pricing {
                        // position: relative;
                        //float: right;
                        //display: inline-block;
                        margin-left: auto;
                        align-self: center;
                        text-align: right;
                        //transform: translateY(-50%);
                        //top: 42%;

                        &:not(.labelled) {
                            top: 47%;

                            @include mq(ib) {
                                top: 22%;
                            }

                            @include mq(tablet) {
                                top: 22%;
                            }
                        }

                        @include mq(mobile) {
                            &.labelled {
                                transform: translateY(-10%);
                            }
                        }

                        /*
                        @include mq(steambrowser) {
                            transform: none;
                            position: absolute;
                            bottom: 9px;
                            right: 12px;
                            float: none;
                            top: inherit;
                        }*/

                        @include mq(mobile) {
                            top: 0;
                        }
                    }

                    .oldPrice {
                        display: inline-block;
                        color: $pcRed;
                        text-align: center;
                        position: relative;
                        margin-right: 30px;
                        font-weight: bold;

                        .crossout {
                            //@include transform(rotate(15deg));
                            transform: rotate(15deg);
                            position: absolute;
                            background-color: #d30000;
                            width: 70%;
                            height: 4px;
                            top: 10px;
                            right: 0;
                        }

                        .was {
                            display: inline-block;
                            text-transform: uppercase;
                            margin-right: 10px;
                            position: relative;
                            top: -4px;
                        }

                        .currsign {
                            font-size: 16px;
                            margin-right: 0;
                            position: relative;
                            top: -8px;
                        }

                        .bignum {
                            font-size: 28px;
                        }

                        .cents {
                            font-size: 14px;
                            position: relative;
                            top: -10px;
                        }

                        .currcode {
                            position: absolute;
                            font-size: 9px;
                            right: -1px;
                            top: 17px;
                            font-weight: normal;
                        }
                    }

                    .priceHolder {
                        //@include border-radius(4px);
                        border-radius: 4px;
                        color: #fff;
                        font-weight: bold;
                        text-transform: uppercase;
                        cursor: pointer;
                        display: inline-block;
                        position: relative;
                        top: 0px;

                        &.bestValue {
                            top: 5px;
                            padding: 2px;
                            background-color: $bestValueColor;
                        }

                        &.mostPopular {
                            top: 5px;
                            padding: 2px;
                            background-color: $mostPopularColor;
                        }

                        .titleTag {
                            width: 115px;
                            font-size: 12px;
                            text-align: center;
                            margin-bottom: 1px;
                        }

                    }

                    .priceButton {
                        //@include border-radius(4px);
                        border-radius: 4px;
                        background-color: $darkBlue;
                        color: #fff;
                        min-width: 115px;
                        font-weight: bold;
                        text-transform: uppercase;
                        cursor: pointer;
                        display: inline-block;

                        .price {
                            text-align: center;
                            position: relative;
                            margin: 5px;

                            .currsign {
                                font-size: 16px;
                                margin-right: 0;
                                position: relative;
                                top: -8px;
                            }

                            .bignum {
                                font-size: 28px;
                            }

                            .cents {
                                font-size: 14px;
                                position: relative;
                                top: -10px;
                            }

                            .currcode {
                                position: absolute;
                                font-size: 9px;
                                right: -1px;
                                top: 17px;
                                font-weight: normal;
                            }
                        }
                    }

                    &.sku257 {
                        .indicator {
                            background-color: $gold;
                        }

                        .bonus .Icon {
                            filter: invert(51%) sepia(100%) saturate(312%) hue-rotate(9deg) brightness(84%) contrast(87%);
                        }

                        .desc {
                            .icon {
                                background-image: url('../images/buy-plat/expanded/platIconGold.png');
                            }

                            .platInfo {
                                color: $gold;
                            }
                        }

                        .priceButton {
                            background-color: $gold;
                        }
                    }

                    &.sku258 {
                        .indicator {
                            background-color: $silver;
                        }

                        .bonus .Icon {
                            filter: invert(53%) sepia(12%) saturate(497%) hue-rotate(169deg) brightness(97%) contrast(84%);
                        }

                        .desc {
                            .icon {
                                background-image: url('../images/buy-plat/expanded/platIconSilver.png');
                            }

                            .platInfo {
                                color: $silver;
                            }
                        }

                        .priceButton {
                            background-color: $silver;
                        }
                    }

                    &.sku259 {
                        .indicator {
                            background-color: $bronze;
                        }

                        .bonus .Icon {
                            filter: invert(29%) sepia(66%) saturate(457%) hue-rotate(341deg) brightness(98%) contrast(87%);
                        }

                        .desc {
                            .icon {
                                background-image: url('../images/buy-plat/expanded/platIconBronze.png');
                            }

                            .platInfo {
                                color: $bronze;
                            }
                        }

                        .priceButton {
                            background-color: $bronze;
                        }
                    }
                }
            }

            &.coupon {
                //@include border-radius(5px);
                border-radius: 5px;
                border: 7px solid $pcRed;
                flex-wrap: wrap;

                .couponTop {
                    background-color: $pcRed;
                    color: #fff;
                    text-align: center;
                    text-transform: uppercase;
                    font-size: 36px;
                    padding: 15px;
                    width: 100%;

                    strong,
                    span {
                        font-weight: bold;
                    }
                }

                ul.platList {
                    li.platRow {
                        height: auto;

                        .pricing {
                            transform: none;
                        }
                    }

                    &.multiplierCoupon {
                        .indicator {
                            background-color: $darkBlue;
                        }

                        .platInfo {
                            color: $darkBlue;
                        }

                        .priceButton {
                            background-color: $darkBlue;
                        }

                        .hasCoupon {
                            .indicator {
                                background-color: $couponRed;
                            }

                            .bonus-plat {
                                color: $couponRed;
                            }

                            .platinum {
                                color: $couponRed;

                                .standard {
                                    color: $darkBlue;
                                }
                            }

                            .priceButton {
                                background-color: $couponRed;
                            }

                            .plusMods {
                                .platinum {
                                    color: $darkBlue;

                                    .bonus-plat {
                                        color: $couponRed;
                                    }
                                }
                            }
                        }

                    }
                }
            }
        }

        .morePacks {
            .comparePacks {
                cursor: pointer;

                //margin-left: 20px;
                .infoIcon {
                    fill: #676767;
                    top: 4px;
                    position: relative;
                    margin-right: 5px;
                }

                @include breakpoint-mobile-only {
                    display: block;
                    padding-bottom: 14px;

                    .pipe {
                        display: none;
                    }

                    .infoIcon {
                        margin-right: 8px;
                    }
                }
            }

            .sectionSelector {
                border-top: 1px solid #c7c7c7;
                border-bottom: 1px solid #c7c7c7;
                padding: 15px 10px;
                margin-top: 20px;

                li {
                    display: inline-block;
                    margin-right: 15px;

                    a {
                        color: #717171;
                        text-transform: uppercase;
                        font-weight: bold;
                        text-decoration: none;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                @include mq(mobile) {
                    display: none;
                }
            }
        }

        .section {
            h4 {
                color: $pcRed;
                font-family: $futureFont;
                text-transform: uppercase;
                font-size: 18px;
                margin: 20px 0;
                display: inline-block;
                font-weight: bold;

                @include breakpoint-mobile-only {
                    margin: 20px 0 8px;
                }
            }

            .cards {
                .card {
                    //transform: scale(1);

                    position: relative;
                    width: 365px;
                    height: 478px;
                    margin: 0 $cardSpacing $cardSpacing 0;
                    float: left;
                    background-color: transparent;
                    perspective: 1000px;
                    /* Remove this if you don't want the 3D effect */
                    border: none;

                    @include mq(steambrowser) {
                        width: 32.5%;
                        min-width: 290px;
                        height: 382px;
                        margin: 3px !important;

                        &:nth-child(3n) {
                            margin: 0 0 10px 0;

                            @include mq(tablet) {
                                margin: 10px;
                            }
                        }
                    }

                    @include mq(mobile) {
                        width: 100% !important;
                        margin: $cardSpacing auto;
                    }

                    path {
                        fill: rgb(255, 255, 255);
                        width: 32px;
                        height: 32px;
                    }

                    .flipContainer {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        -webkit-transition: transform 0.8s;
                        transition: transform 0.8s;
                        transform-style: preserve-3d;
                        -webkit-animation: flashflip 0.05s;

                        .front,
                        .back {
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            -webkit-backface-visibility: hidden;
                            /* Safari */
                            backface-visibility: hidden;
                            border-radius: 10px;
                            overflow: hidden;
                        }

                        .packImage {
                            width: 102%;
                            height: 100%;
                            position: absolute;
                            background-repeat: no-repeat;
                            background-size: 100%;
                            background-position: center;

                            @include mq(steambrowser) {
                                background-size: 110%;
                            }

                            @include mq(table) {
                                background-size: 100%;
                            }

                            @include mq(mobile) {
                                background-size: cover;
                            }
                        }

                        .front {
                            z-index: 2;
                            opacity: 1;

                            @supports (-ms-ime-align:auto) {
                                -webkit-transition: opacity 0.3s;
                                transition: opacity 0.3s;
                                -webkit-animation-timing-function: cubic-bezier(1, -1, 0, 1);
                                animation-timing-function: cubic-bezier(1, -1, 0, 1);
                            }

                            @-moz-document url-prefix() {
                                -webkit-transition: opacity 0.3s;
                                transition: opacity 0.3s;
                                -webkit-animation-timing-function: cubic-bezier(1, -1, 0, 1);
                                animation-timing-function: cubic-bezier(1, -1, 0, 1);
                            }

                            .packImage {
                                -webkit-transition: background-size 0.3s;
                                transition: background-size 0.3s;
                            }

                            .moreInfo {
                                cursor: pointer;
                                position: absolute;
                                right: 0;
                                transform: translateX(calc(100% - 35px));
                                top: 40px;
                                border-radius: 10px 0 0 10px;
                                padding: 6px;
                                -webkit-transition: transform 0.3s;
                                transition: transform 0.3s;

                                @include breakpoint-mobile-only {
                                    padding: 8px 16px 4px 8px;
                                }

                                @include mq(tablet) {
                                    transform: translateX(0);
                                }

                                &:hover {
                                    filter: brightness(110%);
                                }

                                span {
                                    color: white;
                                    position: relative;
                                    top: -7px;
                                    margin-left: 3px;
                                    font-style: italic;

                                    @include breakpoint-mobile-only {
                                        display: none;
                                    }
                                }
                            }

                            .head {
                                position: absolute;
                                bottom: 0;
                                left: 0;
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                height: 31%;
                                padding: 15px;
                                color: #fff;
                                text-transform: uppercase;

                                @include mq(steambrowser) {
                                    height: 38%;
                                }

                                .title {
                                    right: 0;
                                    top: 0;
                                    width: 75%;
                                    font-weight: bold;
                                    text-align: right;
                                    text-transform: uppercase;
                                    //font-family: $openSansFont;
                                    margin-bottom: 15px;
                                    font-size: 19px;
                                    line-height: 22px;

                                    @include mq(steambrowser) {
                                        font-size: 14px;
                                        position: relative;
                                        top: -4px;
                                    }
                                }

                                .plat {
                                    background-image: url('../images/buy-plat/expanded/platIcon.png');
                                    background-repeat: no-repeat;
                                    background-size: 60%;
                                    background-position-x: center;

                                    width: 25%;
                                    left: 0;
                                    top: -8px;
                                    position: relative;

                                    &.hidePlat {
                                        opacity: 0;
                                    }

                                    .num {
                                        text-align: center;
                                        font-weight: bold;
                                        font-size: 32px;

                                        span {
                                            font-weight: initial;
                                            font-size: 13px;
                                            position: relative;
                                            top: -17px;
                                        }
                                    }
                                }
                            }

                            &:hover {
                                .packImage {
                                    background-size: 110%;

                                    @include mq(steambrowser) {
                                        background-size: 120%;
                                    }

                                    @include mq(tablet) {
                                        background-size: 100%;
                                    }
                                }

                                .moreInfo {
                                    transform: translateX(0);
                                }
                            }
                        }

                        .back {
                            z-index: 1;
                            pointer-events: none;
                            transform: rotateY(180deg);
                            background-color: white;
                            overflow: hidden;
                            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);

                            @media screen and (-ms-high-contrast: active),
                            (-ms-high-contrast: none) {
                                transform: none;
                                display: none;
                            }

                            .packImage {
                                filter: blur(5px) grayscale(0.4);
                                height: 40%;
                            }

                            .flipBack {
                                cursor: pointer;
                                z-index: 1;
                                position: absolute;
                                left: 0;
                                top: 40px;
                                border-radius: 0 10px 10px 0;
                                padding: 6px;

                                svg {
                                    opacity: 0.85;

                                    path {
                                        fill: #fff;
                                    }
                                }
                            }


                            .carousel-holder {
                                min-height: 160px;

                                @media screen and (-ms-high-contrast: active),
                                (-ms-high-contrast: none) {
                                    display: none;
                                }

                                @include mq(steambrowser) {
                                    display: none;
                                }

                                .carouselBtn {
                                    position: absolute;
                                    top: 80px;
                                    opacity: 0;
                                    -webkit-transition: opacity 0.5s, left 0.5s, right 0.5s;
                                    transition: opacity 0.5s, left 0.5s, right 0.5s;
                                    background: rgba(0, 0, 0, 0.1);
                                    width: 36px;
                                    height: 40px;
                                    padding-top: 13px;
                                    text-align: center;
                                    cursor: pointer;
                                    color: white;
                                    border-radius: 5px 5px 5px 5px;

                                    &:hover {
                                        background: rgba(0, 0, 0, 0.3);
                                    }

                                    &.carouselNext {
                                        right: 0px;
                                        //border-radius: 5px 0 0 5px;
                                    }

                                    &.carouselPrev {
                                        left: 0px;
                                        //border-radius: 0 5px 5px 0;
                                    }

                                    @include mq(tablet) {
                                        display: none;
                                    }
                                }

                                &:hover {
                                    .carouselNext {
                                        opacity: 1;
                                        right: 20px;
                                    }

                                    .carouselPrev {
                                        opacity: 1;
                                        left: 20px;
                                    }
                                }

                                .VueCarousel {
                                    height: 30%;
                                    margin-bottom: 10px;

                                    .VueCarousel-wrapper {
                                        transform: translate(1px);
                                    }

                                    .VueCarousel-slide {
                                        position: relative;
                                        color: #fff;
                                        //font-family: Arial;
                                        font-size: 24px;
                                        text-align: center;
                                        width: 100%;
                                        height: 160px;

                                        .padImage {
                                            height: 100%;
                                            display: inline-block;
                                            vertical-align: middle;
                                        }

                                        img {
                                            /*                                            max-width: 95%;
                                            max-height: 160px;*/
                                            max-width: 95%;
                                            max-height: 140px;
                                            display: inline-block;
                                            vertical-align: middle;
                                            //@include background-image(radial-gradient(center, ellipse farthest-corner, rgba(0,0,0,1) 0%,rgba(0,0,0,0.14) 60%,rgba(0,0,0,0) 70%));
                                        }

                                        span {
                                            bottom: 3px;
                                            width: 100%;
                                            position: absolute;
                                            display: block;
                                            font-size: 15px;
                                            text-shadow: 0px 0px 3px black;
                                        }
                                    }

                                    .VueCarousel-pagination {
                                        position: absolute;
                                        bottom: -35px;
                                        min-height: 42px;

                                        .VueCarousel-dot-container {
                                            margin: 0 0 10px 0 !important;

                                            .VueCarousel-dot {
                                                padding: 2px !important;
                                                width: 2px !important;
                                                height: 2px !important;
                                                margin: 1px 3px !important;
                                                -webkit-transform: none !important;
                                                transform: none !important;
                                                background-color: #c4c4c4 !important;
                                                -webkit-transition: width 0.2s, height 0.2s, margin 0.2s;
                                                transition: width 0.2s, height 0.2s, margin 0.2s;
                                                top: -3px;
                                                position: relative;
                                                border-radius: 100%;

                                                &.VueCarousel-dot--active {
                                                    background-color: white !important;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.flipped {
                            transform: rotateY(180deg);

                            @media screen and (-ms-high-contrast: active),
                            (-ms-high-contrast: none) {
                                transform: none;
                            }

                            .front {
                                pointer-events: none;

                                @supports (-ms-ime-align:auto) {
                                    opacity: 0;
                                }

                                @-moz-document url-prefix() {
                                    opacity: 0;
                                }

                                @media screen and (-ms-high-contrast: active),
                                (-ms-high-contrast: none) {
                                    display: none;
                                }
                            }

                            .back {
                                pointer-events: auto;

                                @media screen and (-ms-high-contrast: active),
                                (-ms-high-contrast: none) {
                                    display: block;
                                }
                            }
                        }

                        .label {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                        }

                        .items {
                            color: black;
                            text-align: center;
                            background-color: white;
                            padding: 5px 15px 0 15px;
                            position: absolute;
                            width: 100%;
                            height: 230px;
                            -webkit-backface-visibility: hidden;
                            /* Safari */
                            backface-visibility: hidden;

                            .title {
                                text-transform: uppercase;
                                //font-family: $openSansFont;
                                font-weight: bold;
                                width: 100%;
                                font-size: 19px;
                                padding-bottom: 7px;
                                border-bottom: black solid 1px;
                                margin: 10px 0;
                            }

                            .list {
                                height: 175px;
                                overflow-y: auto;
                                -ms-overflow-style: none;

                                @media screen and (-ms-high-contrast: active),
                                (-ms-high-contrast: none) {
                                    height: 375px;
                                }

                                @include mq(steambrowser) {
                                    height: 255px;
                                }

                                &::-webkit-scrollbar {
                                    width: 5px;
                                    height: 5px;
                                }

                                &::-webkit-scrollbar-track {
                                    background-color: rgba(255, 255, 255, 0.1);
                                    border-radius: 10px;
                                }

                                &::-webkit-scrollbar-thumb {
                                    background-color: #b1b1b1;
                                    border-radius: 10px;
                                }

                                ul {
                                    margin-top: 5px;

                                    li {
                                        margin-bottom: 8px;
                                        font-size: 15px;

                                        b {
                                            font-weight: bold;
                                        }
                                    }
                                }

                                .gradientOverlay {
                                    position: absolute;
                                    bottom: 0px;
                                    height: 10px;
                                    width: 100%;
                                    background: linear-gradient(0deg, #ffffff 10%, rgba(255, 255, 255, 0) 100%);

                                    @media screen and (-ms-high-contrast: active),
                                    (-ms-high-contrast: none) {
                                        display: none;
                                    }

                                    @include mq(steambrowser) {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .infoLabel {
                            position: absolute;
                            display: flex;
                            color: #fff;
                            top: -24px;
                            right: 0px;
                            padding: 5px;
                            z-index: 100;
                            text-transform: uppercase;
                            -webkit-backface-visibility: hidden;
                            /* Safari */
                            backface-visibility: hidden;

                            .icon {
                                width: 15px;
                                height: 15px;
                                align-self: center;
                                background-size: 100%;
                                position: relative;
                            }

                            .text {
                                align-self: center;
                                position: relative;

                                @include mq(tablet) {
                                    font-size: 14px;
                                }
                            }

                            &.trending {
                                background-color: #b30000;

                                .icon {
                                    background-image: url('../images/buy-plat/expanded/flameicon.png');
                                }
                            }

                            &.recommended {
                                background-color: #7d973b;

                                .icon {
                                    background-image: url('../images/buy-plat/expanded/thumbsupicon.png');
                                }
                            }

                            &.pcOnly {
                                background-color: #205097;

                                .icon {
                                    margin: 0 2px;
                                    background-image: url("../images/account/platforms/pc.png");
                                }
                            }

                            &.sale {
                                left: 0;
                                right: inherit;
                                display: flex;
                                gap: $SPACE_SMALLER;
                                @include SALE_GRADIENT;
                            }
                        }

                        .infoPrice {
                            position: absolute;
                            left: 0;
                            bottom: 0;
                            color: #fff;
                            width: 96%;
                            text-transform: uppercase;
                            background-color: #000;
                            margin: 2%;
                            padding: 10px 0;
                            cursor: pointer;
                            border-radius: 10px;
                            -webkit-backface-visibility: hidden;
                            /* Safari */
                            backface-visibility: hidden;
                            transition: bottom ease 0.2s;

                            &:hover {
                                bottom: 3px;
                                filter: brightness(110%);
                            }

                            .packInfo {
                                float: right;
                                margin: 7px 10px;
                                font-size: 19px;
                                font-weight: bold;

                                @media (max-width: 1019px) {
                                    margin: 5px;
                                }
                            }

                            &.sale {
                                padding: 6px 0;

                                .packInfo {
                                    margin: 10px 10px;
                                }
                            }

                            .price {
                                float: left;
                                position: relative;
                                font-weight: bold;
                                margin: 0 10px;

                                .currsign {
                                    font-size: 16px;
                                    margin-right: 0;
                                    position: relative;
                                    top: -8px;
                                }

                                .bignum {
                                    font-size: 28px;
                                }

                                .cents {
                                    font-size: 14px;
                                    position: relative;
                                    top: -10px;
                                }

                                .currcode {
                                    position: absolute;
                                    font-size: 9px;
                                    right: -1px;
                                    top: 17px;
                                    font-weight: normal;
                                }

                                .oldPrice {
                                    text-decoration: line-through;
                                    opacity: 0.8;
                                    text-align: center;
                                    font-size: $FONT_SIZE_MIN;
                                    font-weight: $FONT_WEIGHT_BASE;
                                }
                            }
                        }

                        &.purchased,
                        &.disabled {
                            pointer-events: none;

                            .front {
                                filter: brightness(50%) grayscale(100%);
                            }

                            .back {
                                filter: brightness(50%) grayscale(100%);

                                .infoPrice {
                                    //filter: brightness(50%) grayscale(100%);
                                }
                            }

                            .infoLabel {
                                display: none;
                            }

                            &:hover {

                                .packImage,
                                .head {
                                    //filter: none !important;
                                }
                            }
                        }

                    }
                }
            }

            &.primeaccess .cards .card {
                .infoPrice {
                    background-color: $darkBlue;
                }
            }

            &.primeaccess {
                .trending {
                    // for sevagoth prime
                    background-color: #4FDB4F !important;

                    .text {
                        color: #000;
                    }

                    .icon {
                        background-image: url('../images/buy-plat/expanded/wf-flame-black.svg') !important;
                    }
                }
            }
        }

        .featuredCard {
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            overflow: hidden;
            padding-bottom: 21.99%;
            font-size: 1em;
            border: 1px #c7a54b solid;
            cursor: pointer;

            .infoIcon {
                cursor: pointer;
                fill: white;
                position: absolute;
                top: 4%;
                right: 0%;
                width: 4%;
                height: 9%;
            }

            &.bottom {
                margin-top: 20px;
            }

            .bgImage {
                position: absolute;
                width: 101%;
                transition: width .3s;
                top: 50%;
                left: 50%;
                -ms-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
            }

            .purchased {
                .bgImage {
                    filter: brightness(50%) grayscale(1);
                }
            }

            &:hover {
                .bgImage {
                    width: 102%;
                }

                .purchased {
                    .bgImage {
                        width: 101%;
                    }
                }
            }

            .itemList {
                position: absolute;
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: #000000bf;
                color: white;
                padding: 2%;

                &.show {
                    display: block;
                }

                .detailsTitle {
                    //font-family: "Michroma", "Open Sans", Helvetica, sans-serif;
                    text-transform: uppercase;
                    font-size: 2em;
                    margin-bottom: 2.5%;
                    //word-spacing: .3em;
                }

                .list {
                    width: 65%;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    height: 77%;
                    font-size: 1.5em;

                    .listItem {
                        margin-bottom: 2%;
                    }
                }

            }

            .featuredOverlay {
                position: absolute;
                width: 375px;
                //width: 32%;
                //max-width: 400px;
                right: 2%;
                top: 50%;
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);

                .titleText {
                    color: white;
                    text-transform: uppercase;
                    text-shadow: 0 0 10px rgba(0, 0, 0, 0.7); //, 0 0 10px #000000;
                    margin-bottom: 5%;

                    .packName {
                        border-right: 2px #ffffffa3 solid;
                        padding-right: 20px;
                        max-width: 61%;
                        float: left;

                        .small {
                            font-size: 1.1em;
                        }

                        .large {
                            color: white;
                            font-weight: bold;
                            font-size: 2.4em;
                        }
                    }

                    .plat {
                        position: relative;
                        top: 1%;
                        display: flex;
                        float: right;

                        &.hidePlat {
                            opacity: 0;
                        }

                        .platIcon {
                            margin-right: 10%;
                            width: 47px;
                            height: 47px;
                            filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
                        }

                        .num {
                            text-align: center;
                            font-weight: bold;
                            //font-size: 32px;
                            font-size: 2.3em;
                            margin-right: 13px;

                            span {
                                //font-size: 13px;
                                font-weight: bold;
                                font-size: .45em;
                                position: relative;
                                top: -17px;
                            }
                        }
                    }
                }

                .infoPrice {
                    margin: 2% 0% 3% 0%;
                    text-align: center;
                    padding-bottom: 10px;
                    font-weight: bold;
                    color: #674915;
                    //font-family: "Ubuntu", "Open Sans", sans-serif;
                    background-color: #e4bc53;
                    text-transform: uppercase;
                    transition: background-color .3s;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

                    &.purchased {
                        filter: grayscale(1);
                    }

                    &:hover {
                        background-color: #eec864;
                    }

                    .packName {
                        //font-family: "Michroma", "Open Sans", Helvetica, sans-serif;
                        font-size: 1.3em;
                        font-weight: normal;
                    }

                    .packInfo {
                        //float: right;
                        margin: 3% 0 0 6%;
                        //font-size: 22px;
                        font-size: 1.8em;
                        font-weight: bold;
                    }

                    &.sale {
                        padding: 6px 0;

                        .packInfo {
                            margin: 10px 10px;
                        }
                    }

                    .price {
                        //float: left;
                        position: relative;
                        font-weight: bold;
                        //margin: 2% 0 0 22%;
                        border-right: black 1px solid;
                        padding-right: 6%;
                        margin: 2% 0 0 0;

                        .currsign {
                            font-size: 25px;
                            margin-right: 0;
                            position: relative;
                            top: -2px;
                        }

                        .bignum {
                            font-size: 31px;
                        }

                        .cents {
                            font-size: 14px;
                            position: relative;
                            top: -10px;
                        }

                        .currcode {
                            position: absolute;
                            font-size: 9px;
                            right: -1px;
                            top: 17px;
                        }

                        .oldPrice {
                            text-decoration: line-through;
                            opacity: 0.8;
                            text-align: center;
                            font-size: $FONT_SIZE_MIN;
                            font-weight: $FONT_WEIGHT_BASE;
                        }
                    }
                }

                .viewDetails {
                    font-size: 1.5em;
                    text-align: center;
                    margin-top: 5%;
                    color: white;
                    text-transform: uppercase;

                    img {
                        width: 24px;
                        margin-left: 10px;
                        transition: filter .2s;

                    }

                    &:hover {
                        color: lightgrey;

                        img {
                            //filter: drop-shadow(0px 0px 10px black) drop-shadow(0px 0px 10px black) brightness(0.7);
                            filter: brightness(0.7);
                        }
                    }
                }
            }

            @media (min-width: 2300px) {
                font-size: 1.5em;

                .featuredOverlay {
                    width: 520px;
                    right: 5%;

                    .titleText {
                        .plat {
                            .platIcon {
                                width: 67px;
                                height: 67px;
                            }
                        }

                        .packName {
                            padding-right: 4%;
                        }
                    }

                    .infoPrice {
                        .price {
                            .currsign {
                                font-size: 2em;
                            }

                            .bignum {
                                font-size: 2em;
                            }

                            .cents {
                                font-size: 1.2em;

                                .currcode {
                                    font-size: .5em;
                                    right: 2px;
                                    top: 24px;
                                }
                            }
                        }
                    }

                    .viewDetails {
                        a {
                            img {
                                width: 35px;
                            }
                        }
                    }
                }

                .itemList {
                    .detailsTitle {}

                    .list {
                        font-size: 1em;

                        .listItem {
                            margin-bottom: 2.4%;
                        }
                    }
                }
            }

            @media (max-width: 1720px) {
                .itemList {
                    .list {
                        font-size: 1em;
                    }
                }
            }

            @media (max-width: 1200px) {
                .itemList {
                    .detailsTitle {
                        font-size: 1.5em;
                        margin-bottom: 1%;
                    }

                    .list {
                        height: 95%;
                        width: 50%;

                        .listItem {
                            margin-bottom: 1%;
                        }
                    }
                }
            }

            @media (max-width: 875px) {
                font-size: .7em;

                .featuredOverlay {
                    width: 280px;

                    .titleText {
                        .packName {
                            padding-right: 15px;
                        }

                        .plat {
                            .platIcon {
                                width: 35px;
                                height: 35px;
                            }

                            .num {
                                span {
                                    top: -10px;
                                }
                            }
                        }
                    }

                    .infoPrice {
                        .packInfo {
                            margin-top: 4%;
                        }
                    }
                }

                .itemList {
                    .list {
                        .listItem {
                            margin-bottom: 1%;
                        }
                    }
                }
            }

            @include mq(tablet) {

                .featuredOverlay {
                    width: 280px;

                    .titleText {
                        .packName {
                            padding-right: 15px;
                        }

                        .plat {
                            margin-bottom: -8px;

                            .platIcon {
                                width: 30px;
                                height: 30px;
                            }

                            .num {
                                span {
                                    top: -10px;
                                }
                            }
                        }
                    }

                    .infoPrice {
                        padding-bottom: 3px;

                        .packInfo {
                            margin-top: 3.5%;
                        }

                        .price {
                            margin-top: 1%;
                        }
                    }

                    .viewDetails {
                        font-size: 1.2em;
                        margin-top: 1%;
                    }
                }

                .itemList {
                    .detailsTitle {
                        font-size: 1.5em;
                        margin-top: 1%;
                        margin-bottom: 1%;
                    }

                    .list {
                        height: 90%;
                        font-size: 1.2em;

                        .listItem {
                            margin-bottom: 1%;
                        }
                    }
                }
            }


            @include mq(mobile) {
                padding-bottom: 123%;
                font-size: .8em;

                .bgImageMobile {
                    position: absolute;
                    width: 100%;
                }

                .featuredOverlay {
                    width: 100%;
                    top: unset;
                    bottom: 0;
                    right: 0;
                    -ms-transform: none;
                    transform: none;
                    background-color: #0000009e;
                    padding: 5% 3% 4% 3%;

                    .titleText {
                        padding: 5px;

                        .packName {
                            padding-right: 8px;
                            max-width: 61%;
                        }

                        .plat {
                            font-size: 12px;

                            .platIcon {
                                margin-right: 5%;
                                width: 45px;
                                height: 45px;
                            }

                            .num {
                                margin-right: 5px;
                            }
                        }
                    }

                    .viewDetails {
                        padding: 6px;

                        a {
                            img {
                                width: 12px;
                                margin-left: 3px;
                            }
                        }
                    }

                    .infoPrice {
                        margin-top: 5%;
                        border-radius: 5px;
                        padding-bottom: 8px;

                        .packInfo {
                            margin-left: 25%;
                            font-size: 2.2em;
                        }

                        .price {
                            padding-right: 0;
                            border: none;
                            min-width: 90px;
                        }
                    }
                }
            }

            @media (max-width: 375px) {
                font-size: .6em;

                .featuredOverlay {
                    .titleText {
                        .plat {
                            .platIcon {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 320px) {
                font-size: .6em;

                .featuredOverlay {
                    .titleText {
                        .plat {
                            .platIcon {
                                width: 35px;
                                height: 35px;
                            }
                        }
                    }
                }
            }
        }


        #platMerchBanner {
            background-color: rgb(3, 38, 50);
            color: #ffffff;
            width: 100%;
            margin: 15px 0 0 0;
            text-transform: uppercase;
            text-align: center;
            display: block;
            position: relative;
            min-height: 86px;

            .text {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                min-height: 86px;
                font-size: 35px;
                font-family: $futureFont;
                letter-spacing: 2px;
                line-height: 36px;
                min-width: 950px;

                .imgLeft,
                .innerText,
                .imgRight {
                    display: inline-block;
                    position: relative;
                }

                .imgLeft,
                .imgRight {
                    top: -3px;
                }

                .innerText {
                    margin: 0 30px;
                    top: -14px;

                    span {
                        display: block;
                        font-size: 28px;
                    }
                }
            }

            &:hover {
                background-color: rgb(7, 52, 67);
            }

            @media (max-width: 1600px) {
                .text {
                    font-size: 26px;
                    line-height: 30px;

                    .innerText span {
                        font-size: 18px;
                    }
                }
            }

            @media (max-width: 820px) {
                overflow: hidden;

                .text .innerText {
                    margin: 0 10px;
                }
            }

            @include mq(mobile) {
                .text {
                    font-size: 20px;
                    line-height: 22px;
                    min-width: auto !important;

                    .innerText {
                        top: 10px;

                        span {
                            font-size: 14px;
                        }
                    }

                    .imgLeft,
                    .imgRight {
                        display: none;
                    }
                }
            }
        }
    }

    .duviriBundleBanner {

        padding: 62px 0px 75px;
        width: 100vw;
        left: calc(-50vw + 50%);
        position: relative;
        display: flex;
        justify-content: center;
        gap: 32px;
        margin-top: 40px;
        background-color: #1E1E1E;

        @media (max-width: 1339px) {
            flex-direction: column;
            gap: 0px;
            align-items: center;
        }

        @media (min-width: 768px) and (max-width: 1339px) {
            padding: 26px 0px 39px;
        }

        @media (max-width: 767px) {
            padding: 12px 0px 35px;
        }

        &-marketImages {
            display: flex;
            white-space: nowrap;
            margin-left: -50px;

            @media (max-width: 767px) {
                display: none;
            }

            img {
                margin-right: -50px;
            }
        }

        &-marketImagesMobile {
            display: none;

            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            img {
                width: 280px;
                height: auto;
            }
        }

        &-panel {
            margin-left: 50px;
            margin-top: 13px;
            position: relative;

            @media (max-width: 1339px) {
                margin-left: 0px;
                margin-top: 24px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        &-title {
            h2 {
                color: #CFBF89;
            }
        }

        &-description {
            color: white;
        }

        &-buttonRow {
            margin-top: 16px;

            @media (min-width: 1440px) {
                margin-top: 0px;
                position: absolute;
                bottom: 0px;
            }
        }
    }

    .lastChanceBanner {
        background: url(/images/promo/last-chance/plat-bundle-bg.svg) top center no-repeat rgba(0, 0, 0, 1);
        width: 100vw;
        left: calc(-50vw + 50%);
        position: relative;
        display: flex;
        justify-content: center;
        padding: 10px 0;
        text-align: center;
        margin-top: 20px;

        @include mq-tablet-only {
            background: url(/images/promo/last-chance/plat-bundle-tablet-bg.svg) top center no-repeat rgba(0, 0, 0, 1);
        }

        @include mq-mobile-only {
            background: url(/images/promo/last-chance/plat-bundle-mobile-bg.svg) 50% 20% no-repeat rgba(0, 0, 0, 1);
        }

        .lastChancePacks {
            display: flex;
            justify-content: center;
            margin: 20px 0;

            @include mq-mobile-only {
                display: block;
                margin: 0 0 10px;
            }
        }

        .comparePacks {
            display: none;
        }

        &-title {
            position: relative;
            z-index: 10;
            margin: 40px 0 0;

            h2 {
                text-transform: uppercase;
                color: rgba(245, 230, 160, 1);
                font-weight: 700;
                font-size: 48px;

                @include mq-mobile-only {
                    font-size: 36px;
                }
            }
        }

        &-description {
            position: relative;
            z-index: 10;

            p {
                color: #fff;
            }

            a {
                color: #fff;
                text-decoration: underline;
            }
        }

        .lastChanceBannerContainer {
            background: radial-gradient(50% 50% at 50% 50%, rgba(167, 132, 78, 0.33) 0%, rgba(167, 132, 78, 0) 100%);
            width: 80%;
            position: relative;

            .cornerArt {
                position: absolute;
                width: 88px;
                height: 91px;
                background-image: url(/images/promo/last-chance/corner-art-lg.svg);

                @include mq-mobile-only {
                    background-image: url(/images/promo/last-chance/corner-art-sm.svg);
                    width: 34px;
                    height: 33px;
                }
            }

            .cornerArtTL {
                top: 0;
                left: 0;

                @include mq-mobile-only {
                    top: -4px;
                    left: -32px;
                }
            }

            .cornerArtTR {
                top: 0;
                right: 0;
                transform: rotate(90deg);

                @include mq-mobile-only {
                    top: -4px;
                    right: -32px;
                }
            }

            .cornerArtBL {
                bottom: 0;
                left: 0;
                transform: rotate(270deg);

                @include mq-mobile-only {
                    bottom: -4px;
                    left: -32px;
                }
            }

            .cornerArtBR {
                bottom: 0;
                right: 0;
                transform: rotate(180deg);

                @include mq-mobile-only {
                    bottom: -4px;
                    right: -32px;
                }
            }

        }

        //.lastChanceBannerContainer::before {
        //    content: '';
        //    position: absolute;
        //    width: 1476px;
        //    height: 690px;
        //    top: 10px;
        //    left: 0;
        //    background-size: contain;
        //    background-repeat: no-repeat;
        //    background-image: url(/images/promo/last-chance/plat-bundle-bg.svg); /* URL to your top-left SVG */
        //}



    }

    //Start Prime Resurgence
    .ContentSection-content {
        padding: 0;
        margin-bottom: 10px;

        a {
            color: grey;
        }
    }

    .SectionTitle {
        color: #d30000;
        text-transform: uppercase;
        font-size: 18px;
        margin: 32px 0 0 0;
        display: inline-block;
        font-weight: bold;

        @include breakpoint-mobile-only {
            padding-bottom: 0;
            margin: 20px 0 8px;
        }
    }

    #tokens {
        .Section-content {
            padding-bottom: $SPACE_LARGE;
        }
    }

    .TokenGrid {
        margin-top: 25px;
    }

    //End Prime Resurgence


    &.lang-fr {
        .featuredCard {
            font-size: .9em !important;

            .featuredOverlay {
                .infoPrice {
                    .price {
                        padding-right: 2% !important;
                    }

                    .packInfo {
                        margin: 3% 0 0 2% !important;
                    }
                }
            }
        }
    }

    &.lang-fr,
    &.lang-es,
    &.lang-pt-br,
    &.lang-pl {
        #colorbox.modCardOverlay {
            .card {
                .cardtitle {
                    font-size: 15px;
                }

                .cardEffect {
                    font-size: 13px;
                }
            }
        }
    }

    &.lang-ru {
        .featuredCard {
            font-size: .9em !important;

            .featuredOverlay {
                .titleText {
                    .packName {
                        .small {
                            margin-bottom: 7px !important;
                        }
                    }
                }
            }
        }

        #colorbox.modCardOverlay {
            .card {
                .cardtitle {
                    font-size: 15px;
                }

                .cardEffect {
                    display: block;
                    font-size: 11px;
                    margin: auto;
                    width: 99%;
                }
            }
        }
    }


    &.lang-fr,
    &.lang-es,
    &.lang-pt-br,
    &.lang-tr,
    &.lang-pl,
    &.lang-zh-hans,
    &.lang-zh-hant {
        #buyplatExpanded {
            #platMerchBanner {
                overflow: hidden;

                .text {
                    min-width: 1140px;
                }
            }

            .featuredCard {
                @include mq(mobile) {
                    font-size: .7em;
                }

                .featuredOverlay {
                    .titleText {
                        .packName {
                            .large {
                                font-size: 2.1em;
                            }
                        }
                    }
                }
            }
        }
    }

    &.lang-uk {
        #colorbox.modCardOverlay {
            .card {
                .cardtitle {
                    font-size: 15px;
                }

                .cardEffect {
                    font-size: 11px;
                }
            }
        }
    }

    &.lang-de {
        #colorbox.modCardOverlay {
            .card {
                .cardtitle {
                    font-size: 15px;
                }

                .cardEffect {
                    font-size: 13px;
                }
            }
        }
    }
}

@import "regalaya_packs";

.lang-fr {
    .TokenCard-footerInner {
        @include mq-ipad-up {
            .buyNow {
                @media (max-width: 1000px) {
                    font-size: 13px;
                }
            }
        }
    }
}